import React from 'react';
import './Header.css';
import logo from './Images/logoSamol.png'; // Assurez-vous que le chemin est correct

function Header() {
  return (
    <header className="headerhead">
      <a href="/"><img src={logo} alt="Logo" className="logo" /></a>
      <nav className="header-nav">
        <ul>
          <li><a href="/"><b>Accueil</b></a></li>
          <li><a href="/services"><b>Services</b></a></li>
          <li><a href="/portfolio"><b>Portefeuille</b></a></li>
          <li><a href="/faq"><b>FAQ</b></a></li>
          <li><a href="/register"><b>S'inscrire</b></a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
