import React from 'react';
import Header from './Header';
import Footer from './Footer';
import ScholarisImg from './Images/AccueilScolaris1.png';
import MaParoisseImg from './Images/MaParoisse.jpg';
import SbuilderImg1 from './Images/Sb1.jpg';
import SbuilderImg2 from './Images/Sb2.jpg';
import SbuilderImg3 from './Images/Sb3.jpg';
import { Link } from 'react-router-dom';
import "./portfolio.css"; // import du fichier CSS pour le style de la page

function Portfolio() {
  return (
    <div> <Header/>
      <div className="portfolio-header">
          <h2>Nos réalisations</h2>
          <p>Nous avons travaillé sur plusieurs projets de pointe pour des entreprises et des particuliers qui nous ont fait confiance et ont été satisfait de la qualité du travail. <br/> Nous parlons brievement de quelques un de ses projets </p>
      </div>
      <div className="portfolio-content">
          <h2>SmartEducka : LOGICIEL DE GESTION D'ECOLE</h2>
          <p>SmartEducka est un outil puissant et efficace pour la gestion administrative des écoles. Avec ses nombreuses fonctionnalités, il permet une gestion centralisée et automatisée de toutes les activités administratives, ce qui réduit les coûts et améliore la communication entre les différents acteurs de l'école. Il est facilement accessible grâce à son interface utilisateur conviviale et intuitive. Nous recommandons vivement SmartEducka à toutes les écoles qui souhaitent améliorer leur gestion administrative.</p> 
          <img src={ScholarisImg} alt="SmartEducka"/>
        <div className="portfolio-description">
          <div className="portfolio-text">
          <h2><b>Avantages de SmartEducka</b></h2>
              <p> SmartEducka présente plusieurs avantages pour les écoles, tels que </p>
              <ul>
                <li>Une gestion centralisée de toutes les activités administratives ;</li>
                <li>Une automatisation des processus administratifs pour une gestion plus rapide et plus efficace ;</li>
                <li>Une réduction des coûts liés à l'administration ;</li>
                <li>Une meilleure communication entre les différents acteurs de l'école (élèves, enseignants, parents, personnel administratif) ;</li>
                <li>Une accessibilité facile et rapide aux informations grâce à une interface utilisateur intuitive et conviviale.</li>
             </ul>
             <a href="https://samol-tech.com/SCHOLARIS_SET_UP.zip" download>Télécharger SmartEducka</a>
          </div>
        </div>
        </div>
        <div className="portfolio-content">
          <h2>MaParoisse : LOGICIEL DE GESTION DE PAROISSE</h2>
          <p>MaParoisse est un outil puissant et efficace pour la gestion administrative des églises. Avec ses nombreuses fonctionnalités, il permet une gestion centralisée et automatisée de toutes les activités administratives d'une paroisse, ce qui réduit les coûts et améliore la communication entre les différents acteurs de la paroisse. Il est facilement accessible grâce à son interface utilisateur conviviale et intuitive. Nous recommandons vivement MaParoisse à toutes les paroisses qui souhaitent améliorer leur gestion administrative.</p> 
          <img src={MaParoisseImg} alt="MaParoisse"/>
          <div className="portfolio-description">
          <div className="portfolio-text">
            <h2><b>Avantages de MaParoisse</b></h2>
            <p>MaParoisse présente plusieurs avantages pour les églises, tels que :</p>
            <ul>
              <li>Une gestion centralisée de toutes les activités administratives ;</li>
              <li>Une automatisation des processus administratifs pour une gestion plus rapide et plus efficace :</li>
                <ul>
                    <li>La gestion de la catéchèse ;</li>
                    <li>La gestion du secrétariat.</li>
                </ul>
              <li>Une réduction des coûts liés à l'administration ;</li>
              <li>Une meilleure communication entre les différents acteurs de la paroisse (clergé et employés) ;</li>
              <li>Une accessibilité facile et rapide aux informations grâce à une interface utilisateur intuitive et conviviale.</li>
            </ul>
            <a href="https://samol-tech.com/MaParoisse_SET_UP.zip" download>Télécharger MaParoisse</a>
          </div>
          </div>
          </div>
          <div className="portfolio-mobile">
          <h2>SENTENSES BUILDER : APPLICATION DE JEUX </h2>
          <div className="image">
            <img src={SbuilderImg1} alt="Sbuilder1" />
            <img src={SbuilderImg2} alt="Sbuilder2" />
            <img src={SbuilderImg3} alt="Sbuilder3" />
          </div>
        <div className="portfolio-description">
          <div className="portfolio-text">
          <h2><b>Description</b></h2>
              <p> 
                Sentence Builder est une application mobile ludique dédiée aux apprenants de la langue anglaise.
                Elle a pour objectif d'aider l'utilisateur à faire des phrases correctes en anglais.
                Cela à partir d'un groupe de mots proposés en désordre.
                En cas de mauvaise réponse, la correction s'affiche automatiquement.
                L'utilisateur a aussi la possibilité de retirer tout mot placé par erreur en touchant le mot concerné.
              </p>
              <a href="https://play.google.com/store/apps/details?id=com.sbbymiessan_free">Télécharger Sentense Builder</a>
          </div>
        </div>
        </div>
      <Link to="/contact" class="btn">Nous contacter</Link>
      <Footer/>
    </div>
    
  );
}

export default Portfolio;