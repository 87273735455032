import React from 'react';
import Header from './Header';
import Footer from './Footer';
// import { Link } from 'react-router-dom';
import './HomePage.css';
import image1 from './Images/gbtP.jpg';
import image2 from './Images/gbtP1.jpg';
import image3 from './Images/gbtP2.jpg';
import image4 from './Images/gbtP3.jpg';
import webAppImg from './Images/gbtP4.jpg';
import websiteImg from './Images/gbtP5.jpg';
import networkImg from './Images/gbtP6.jpg';
import softwareImg from './Images/gbt4.jpg';
import './about.css';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: 0
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      this.setState(state => ({
        currentImageIndex: (state.currentImageIndex + 1) % 4
      }));
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const images = [image1, image2, image3, image4];
    return (
      <div>
       <div className="header-container">
       <Header/>
      </div>
      <div className="homepage-container">
      <div className="welcome-section">
        <h1><b>BIENVENUE CHEZ SAMOL TECHNOLOGIES (SATECH)</b></h1>
      </div>
      <div className="slideshow-container">
        {images.map((image, index) => (
          <div
            className={`slideshow-image ${
              index === this.state.currentImageIndex ? 'active' : ''
            }`}
            key={index}>

            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
        </div>
          <div className="company-presentation">
            <h2> Une entreprise innovante en solution informatique</h2>
            <ul>  
              <p>
              SAMOL TECHNOLOGIES est une entreprise spécialisée dans le <b> développement de logiciels, de sites web, développement d'applications mobiles developpement de logiciels de gestion informatique. </b> Nous proposons également <b> des services d'installation et de configuration de réseaux d'entreprises ainsi que bien d'autres solutions technologiques pour les entreprises.</b> 
             <br/> Fondée en 2018, l'entreprise travail pour devenir un leader dans le domaine des technologies de l'information.
            </p>
            </ul>
            </div>
            <main>
            <section>
            <header>
            <h1>A PROPOS DE NOUS</h1>
            <p>SAMOL TECHNOLOGIES</p>
            </header>
            <article>
            <h2>Une entreprise d'ingénerie logiciel</h2>
            <p>SAMOL TECHNOLOGIES est une entreprise spécialisée dans</p>
            <ul>
            <li>Le développement de logiciels de gestion</li>
            <li>La Conception de sites web</li>
            <li>La création d'applications mobiles</li>
            <li>L'installation et de configuration de réseaux d'entreprises</li>
            </ul>
            <p>
            Et bien d'autres solutions technologiques pour les entreprises.
            </p>
            </article>
            </section>
            <section>
            <h2>Une entreprise dirigée par les professionnels du secteur</h2>
            <div className="about-item">
            <img src={webAppImg} alt="Web Application Development" />
            <div className="about-text">
            <p>
            Notre entreprise est composée d'une équipe de professionnels de l'informatique passionnés et expérimentés, qui travaillent en étroite collaboration avec nos clients pour comprendre leurs besoins et leur fournir des solutions personnalisées pour répondre à leurs exigences commerciales. Nous sommes fiers de proposer des solutions technologiques de haute qualité qui aident nos clients à améliorer leur productivité, leur efficacité et leur rentabilité..
            </p>
            </div>
            </div>
            </section>
            <section>
            <h2>Le secret de notre succès</h2>
            <div className="about-item">
            <img src={websiteImg} alt="Website Design" />
            <div className="about-text">
            <p>
            Nous croyons que la clé du succès de nos clients réside dans la qualité de nos atouts. C'est pourquoi nous sommes engagés à fournir des solutions informatiques de haute qualité, en utilisant les dernières technologies disponibles. Nous sommes à la pointe de l'innovation et nous sommes fiers de notre capacité à résoudre les problèmes les plus complexes et les plus exigeants pour nos clients.
            </p>
            </div>
            </div>
            </section>
            <section>
            <h2>Nous développons des sites web de qualité</h2>
            <div className="about-item">
            <img src={networkImg} alt="Network Installation and Configuration" />
            <div className="about-text">
            <p>
            L'entreprise a également une grande expérience dans le développement de sites web. Elle peut créer des sites web de toute envergure, des sites web statiques aux sites web dynamiques et interactifs, en passant par les sites web de commerce électronique. Les sites web développés par SAMOL TECHNOLOGIES sont conçus pour être visuellement attractifs et conviviaux, tout en étant optimisés pour les moteurs de recherche pour atteindre une visibilité maximale sur le web.
            </p>
            </div>
            </div>
            </section>
            <section>
            <h2>Nous sommes experts en développement de logiciels</h2>
            <div className="about-item">
            <img src={softwareImg} alt="Software Development" />
            <div className="about-text">
            <p>
            Nous sommes spécialisés dans le développement de logiciels de gestion pour les entreprises. Nous développons des solutions personnalisées pour répondre aux besoins spécifiques de nos clients, en utilisant les dernières technologies et les meilleures pratiques de développement. Nous travaillons avec nos clients à chaque étape du processus de développement pour nous assurer que la solution finale répond à leurs exigences.
            </p>
            </div>
            </div>
            </section>
            </main>
            </div>
            <df-messenger
  intent="WELCOME"
  chat-title="SoniaAssistanteWeb"
  agent-id="926c948e-9249-4a23-86a2-ab08e594f84a"
  language-code="en"
></df-messenger>
              <Footer />
            </div>
        
            );
            }
        }
          

      export default HomePage;

