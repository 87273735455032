import { Routes, Route, BrowserRouter as Router} from 'react-router-dom';
import HomePage from './HomePage';
import About from './about';
import Services from './services';
import Portfolio from './portfolio';
import FAQ from './faq';
import Register from './register';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/register" element={<Register/>} />
      </Routes>
    </Router>
  );
}

export default App;
