import React, { useState } from 'react';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import './register.css'

function Register() {
  const [formData, setFormData] = useState({
    email: '',
    nom: '',
    telephone: '',
    prestation: '',
    cahierdecharge: ''
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('https://samol-tech.com/insert.php', formData);
      console.log(response.data);
      alert('Votre demande a été envoyée avec succès!');
      setFormData({
        email: '',
        nom: '',
        telephone: '',
        prestation: '',
        cahierdecharge: ''
      });
    } catch (error) {
      console.log(error);
      alert('Une erreur s\'est produite. Veuillez réessayer plus tard.');
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <div className="backgrond"> 
      <Header/>
      <div className="register-container">
        
  <form className="form-container" onSubmit={handleSubmit}>
    <p>Enregistrez-vous gratuitement pour recevoir nos offres</p>
    <label>
      Email
      <input type="email" name="email" value={formData.email} onChange={handleChange} />
    </label>
    <label>
      Nom
      <input type="text" name="nom" value={formData.nom} onChange={handleChange} />
    </label>
    <label>
      Téléphone
      <input type="text" name="telephone" value={formData.telephone} onChange={handleChange} />
    </label>
    <div className='prestation-contenaire'>
    <label> 
    Prestation 
    <select id="prestation" name="prestation" value={formData.prestation} onChange={handleChange}>
      <option value="">Sélectionnez une prestation</option>
      <option value="Développement de sites internet">Développement de sites internet</option>
      <option value="Applications mobiles">Applications mobiles</option>
      <option value="Applications de gestion">Applications de gestion</option>
      <option value="Installation et configuration de réseaux d'entreprise">Installation et configuration de réseaux d'entreprise</option>
      <option value="Autre prestation">Autre prestation</option>
    </select>
    </label>
    </div>
    <div>
    <label>
      Breve description de la prestation
      <textarea id="cahierdecharge" name="cahierdecharge" value={formData.cahierdecharge} onChange={handleChange} />
    </label>
    </div>
    <button type="submit">S'inscrire</button>
  </form>
  </div>
  <Footer/>
</div>

  );
}

export default Register;